import { mapActions, mapGetters } from 'vuex';
import { ACTIONS, GETTERS, NAMESPACE as PhishingBadgeNameSpace } from './PhishingBadgeStore';

export const {
  confirmPhishingBadge,
  rejectPhishingBadge,
  phishingBadgeData,
} = {
  ...mapGetters(PhishingBadgeNameSpace, {
    phishingBadgeData: GETTERS.phishingBadgeData,
  }),

  ...mapActions(PhishingBadgeNameSpace, {
    confirmPhishingBadge: ACTIONS.confirmPhishingBadge,
    rejectPhishingBadge: ACTIONS.rejectPhishingBadge,
  }),
};
