import axios from 'axios';
import config from '@/config';

const api = {
  businessIntelligence: {
    company: {
      report: ({ companyUuid, accessKey }) => (
        axios.get(`${config.data.serverUrl}/api/business-intelligence/company/${companyUuid}/report`, {
          params: {
            access_key: accessKey,
          },
        })
      ),
    },
  },
  csOperator: {
    getPublicContactData: ({ csOperatorUuid }) => (
      axios.get(`${config.data.serverUrl}/api/cs-operator/${csOperatorUuid}`)
    ),
  },
};

export default api;
