import get from 'lodash/get';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import some from 'lodash/some';

import { LOG_TYPE, logger } from '@emobg/web-utils';

export const parseApiErrorMessage = ($t, $i18n, error, context) => {
  if (some([$t, $i18n, error], isNil)) {
    throw new Error('Missing translator plugin or error instance');
  }
  const internalApiErrorMessage = get(error, 'response.data.message') || get(error, 'message');

  logger.message(internalApiErrorMessage, LOG_TYPE.error);

  const translatableKey = get(error, 'response.data.key') || get(error, 'data.key') || get(error, 'key');

  const contextTranslatableKey = `api.errors.${context}.${translatableKey}.description`;
  const apiTranslatableKey = `api.errors.${translatableKey}`;

  const possibleStoredKeys = [contextTranslatableKey, apiTranslatableKey];
  const messageKey = find(possibleStoredKeys, key => $i18n.keyExists(key, 'strict'));

  const translatedMessage = isNil($t(messageKey))
    ? internalApiErrorMessage
    : $t(messageKey);

  return translatedMessage || $t('notifications.whooops');
};
