<template>
  <div class="d-flex justify-content-center p-4">
    <ui-loader v-if="isLoading" />
    <div
      v-else
      class="d-flex mw-50 justify-content-center"
    >
      <div
        v-if="title || descriptionMessage"
        class="text-center"
      >
        <div
          class="emobg-font-large my-4 emobg-font-weight-bold"
          :class="{
            'emobg-color-danger': !isEmpty(errorCode)
          }"
          v-html="title"
        />
        <div
          class="emobg-font-medium"
          v-html="descriptionMessage"
        />
        <div class="d-flex flex-column pt-4">
          <ui-button
            v-if="action === PHISHING_BADGE_ACTIONS.confirm"
            v-bind="fetchButtonSpecs()"
            class="my-2"
            :data-test-id="dataTestIdConfirm"
            @clickbutton="clickAction"
          >
            {{
              mustReportDamages
                ? $t('PhishingBadge.ValidatePhishing.Success.reportDamages')
                : $t('buttons.try_again')
            }}
          </ui-button>

          <ui-button
            v-if="!isEmpty(errorCode)
              && !isHandledError
              && !isEmpty(get(csOperator, 'customer_service_phone'))"
            v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
            class="my-2"
            data-test-id="call_us-button"
            @clickbutton="callCustomerService"
          >
            {{ $t('buttons.call_us') }}
          </ui-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { LOG_TYPE, logger } from '@emobg/web-utils';

import Api from '@/vue/api/internal';
import { parseApiErrorMessage } from '@/utils/apiHelpers';
import { DATETIME_FORMATS } from '@/constants/datetimeFormats';

import { confirmPhishingBadge, phishingBadgeData, rejectPhishingBadge } from '@Shared/PhishingBadge/store/PhishingBadgeMapper';
import { setLoaderStatus } from '@Shared/store/Loader/LoaderMapper';
import { useTheme } from '@/composable/Theme/useTheme';

import { HANDLED_ERRORS, PHISHING_BADGE_ACTIONS } from './constants';

export default {
  name: 'PhishingBadge',

  props: {
    token: {
      type: String,
      default: undefined,
    },
    action: {
      type: String,
      default: PHISHING_BADGE_ACTIONS.reject,
    },
    csOperatorUuid: {
      type: String,
      default: undefined,
    },
  },
  setup() {
    const { fetchButtonSpecs } = useTheme();

    return { fetchButtonSpecs };
  },

  data() {
    return {
      isLoading: true,
      descriptionMessage: null,
      title: null,
      errorCode: null,
      csOperator: undefined,
    };
  },
  computed: {
    phishingBadgeData,

    isHandledError() {
      return includes(HANDLED_ERRORS, this.errorCode);
    },
    mustReportDamages() {
      return isEmpty(this.errorCode) && get(this, 'csOperator.configuration.has_damage_report');
    },
    dataTestIdConfirm() {
      return `${this.mustReportDamages ? 'report_damages' : 'try_again'}-button`;
    },
  },
  async created() {
    this.setLoaderStatus(false);
    this.PHISHING_BADGE_ACTIONS = PHISHING_BADGE_ACTIONS;
    this.performAction(this.action);
  },
  methods: {
    get,
    isEmpty,
    setLoaderStatus,
    confirmPhishingBadge,
    rejectPhishingBadge,

    clickAction() {
      return this.mustReportDamages
        ? this.reportDamages()
        : this.performAction(PHISHING_BADGE_ACTIONS.confirm);
    },

    async performAction(action) {
      const isConfirmAction = action === PHISHING_BADGE_ACTIONS.confirm;

      if (this.csOperatorUuid) {
        const response = await Api.csOperator.getPublicContactData({ csOperatorUuid: this.csOperatorUuid });
        this.csOperator = get(response, 'data');
      }

      try {
        this.isLoading = true;
        if (isConfirmAction) {
          await this.confirmPhishingBadge({ token: this.token });
        } else {
          await this.rejectPhishingBadge({ token: this.token });
        }

        const keyPrefix = isConfirmAction
          ? 'Validate'
          : 'Refuse';
        this.title = this.$t(`PhishingBadge.${keyPrefix}Phishing.Success.title`);

        const descriptionMessage = this.mustReportDamages
          ? 'description'
          : 'descriptionWithoutReportDamages';

        this.descriptionMessage = isConfirmAction
          ? this.$t(`PhishingBadge.ValidatePhishing.Success.${descriptionMessage}`)
          : '';
        this.errorCode = null;
      } catch (error) {
        this.handleError({
          error: get(this, 'phishingBadgeData.error', error),
          csOperator: this.csOperator,
        });
      } finally {
        this.isLoading = false;
      }
    },
    handleError({ error, csOperator }) {
      logger.message(error, LOG_TYPE.error);

      const errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);
      this.errorCode = get(error, 'key') || 'unknown_error';

      this.title = this.isHandledError
        ? this.$t('api.errors.badge.title')
        : this.$t('notifications.whooops');
      this.descriptionMessage = this.isHandledError
        ? errorMessage
        : this.$t('PhishingBadge.GenericError.description', {
          customerServiceOpeningHours: get(csOperator, 'customer_service_opening_hours'),
        });
    },
    callCustomerService() {
      window.open(`tel:${get(this.csOperator, 'customer_service_phone')}`);
    },
    reportDamages() {
      const subject = this.$t('PhishingBadge.ValidatePhishing.Success.reportDamagesEmail.subject', {
        licensePlate: get(this, 'phishingBadgeData.data.licensePlate'),
      });
      const bodyParams = {
        ...pick(get(this, 'phishingBadgeData.data'), ['bookingReference', 'csOperatorName']),
        start: moment(get(this, 'phishingBadgeData.data.start')).format(DATETIME_FORMATS.emailReportBookingDates),
        end: moment(get(this, 'phishingBadgeData.data.end')).format(DATETIME_FORMATS.emailReportBookingDates),
      };

      const body = encodeURIComponent(this.$t('PhishingBadge.ValidatePhishing.Success.reportDamagesEmail.body', bodyParams));
      window.open(`mailto:${get(this, 'phishingBadgeData.data.csOperatorEmail')}?subject=${subject}&body=${body}`);
    },
  },

};
</script>
